/**
 * @file
 * Alignment classes for text and block level elements.
 */

 .unstyled {
  list-style-type: none !important;

  >li {
    list-style: none;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

/**
 * Alignment classes for block level elements (images, videos, blockquotes, etc.)
 */
.align-left {
  float: left;
}

.align-right {
  float: right;
}

.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.alignfull {
  margin-left: calc(-50vw + 50%) !important;
  margin-right: calc(-50vw + 50%) !important;
  max-width: 100vw !important;
  width: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alignwide {
  margin-left: calc(-42.5vw + 50%) !important;
  margin-right: calc(-42.5vw + 50%) !important;
  width: auto !important;
  max-width: 100vw !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alignleft:not(.wp-block-embed) {
  display: inline !important;
  float: left;
  margin-right: 1.5em;
}

.alignright:not(.wp-block-embed) {
  display: inline !important;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  //display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.hidden,
.hide {
  display: none !important;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

.invisible {
  visibility: hidden;
}

// Banners in the top section shift under the header:
.potnets .region-top>div:first-child.para--img_banner {
  margin-top: -165px;
}

.content-css {
  margin-top: 1rem;

  .expandAnchor {
    text-decoration: none;
    color: #42B5B5;

    .expand-css {
      display: flex;
      alignItems: center;
      fontSize: 16px;
    }
  }
}

.multistep-form-btns {
  border-top: 1px solid;
  //margin-bottom: 2rem;
}

.form-error {
  color: #f44336;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

// table {
//   border: none;
//
//   td {
//     border: none;
//     box-shadow: none;
//     outline: none;
//   }
// }
//
// .table-style-list {
//   padding-left: 48px;
//   border: 0;
//   display: block;
//
//   tr {
//     background-color: transparent;
//   }
//
//   ul li {
//     list-style: none;
//     position: relative;
//     font-weight: 300;
//
//     strong {
//       font-weight: 300;
//     }
//
//     &:before {
//       background: url("../public/assets/list-icon.png");
//       content: '';
//       width: 30px;
//       height: 26px;
//       display: inline-block;
//       position: absolute;
//       left: -35px;
//       top: 5px;
//     }
//   }
// }
//
// .table-style-quote-bg {
//   background: url("../public/assets/bg0002.png");
//   background-repeat: no-repeat!important;
//   background-position: left top;
//   padding-left: 48px;
//   padding-top: 35px;
//   padding-bottom: 30px;
//   background-size: auto 100%!important;
//   display: block;
//
//   tr {
//     margin: 0;
//     padding: 0;
//     position: relative;
//     display: block;
//     background: no-repeat;
//     border: 0;
//
//     td {
//       margin: 0;
//       padding: 0;
//       border: 0;
//       font-size: 20px;
//       font-family: 'Lato-Light';
//       line-height: 29px;
//     }
//   }
// }
//
// table {
//   border: 0;
//   border-spacing: 0;
//   font-size: 0.857em;
//   margin: 10px 0;
//
//   tbody {
//     border-top: 1px solid #ccc;
//
//     tr,
//     tr.event {
//       background: rgba(0,0,0,0.063);
//     }
//
//     tr td,
//     tr th {
//       padding: 4px 9px;
//       border: 1px solid #fff;
//       text-align: left;
//     }
//   }
// }

.infinite-scroll-component {
  @media only screen and (min-width: 769px) {
    min-width: calc(100% + 18px) !important;
    min-height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
    width: 100%;
  }
}

// .single-slide > div > div,
// .single-slide .slick-slide > div > div {
//   max-height: 1000px;
//   height: auto !important;
// }

.single-slide img {
  object-fit: contain !important;
}

@media print {
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .thumb-nav {
    display: none !important;
  }

  .MuiAccordion-root .MuiCollapse-hidden {
    visibility: visible !important;
    height: auto !important;
    overflow: visible !important;
  }
  .MuiAccordionSummary-expandIcon {
    display: none !important;
  }
}